.tour_details_page {
  box-shadow: -3px 3px 12px 0px #7878751a;
  box-shadow: 2px -2px 12px 0px #7878751a;
  background-color: $white;
  padding: 20px;
  border-radius: 16px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  gap: 32px;

  @include phone {
    flex-direction: column;
  }

  .left_side {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include phone {
      width: 100%;
    }

    .tour_overview {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .overview {
        font-size: 16px;
        font-weight: 800;
        line-height: 26px;
        color: $text-100;
      }

      .summery_section {
        display: flex;
        flex-direction: column;
        gap: 10px;
        background-color: $surface_medium;
        padding: 12px;
        width: 100%;
        box-sizing: border-box;
        border-radius: 12px;

        .total_section {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .total_price {
            font-size: 18px;
            line-height: 24px;
            font-weight: 800;
            margin: 0;
          }
        }

        .summery_line {
          width: 100%;
          height: 1px;
          background-color: $divider_50;
        }

        .summery_elements {
          display: flex;
          flex-direction: column;
          gap: 6px;
          width: 100%;
          box-sizing: border-box;

          .element_section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            box-sizing: border-box;

            .element_text {
              color: $text_50;
              font-size: 14px;
              line-height: 22px;
              margin: 0;
            }

            .text_bold {
              font-weight: 500;
            }
          }
        }
      }

      .custom_adjustments {
        display: flex;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
        background-color: $surface_20;
        padding: 12px;
        border-radius: 12px;

        .adjustments_title_field {
          display: flex;
          width: 100%;
          box-sizing: border-box;
          justify-content: space-between;

          .adjustments_title {
            margin: 0;
            font-size: 16px;
            line-height: 26px;
            font-weight: 800;
          }

          .arrow_icon {
            transform: rotate(-90deg);
            transition: all 0.3s;
          }

          .arrow_top {
            transform: rotate(-270deg);
            transition: all 0.3s;
          }
        }
      }
    }
  }

  .right_side {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include phone {
      width: 100%;
    }

    .notes_title {
      font-size: 16px;
      font-weight: 800;
      line-height: 26px;
      color: $text-100;
    }

    .messages_section {
      display: flex;
      flex-direction: column;
      gap: 16px;
      background-color: $surface_medium;
      padding: 12px;
      width: 100%;
      box-sizing: border-box;
      border-radius: 12px;

      .notes_form {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .note_field {
          display: flex;
          flex-direction: column;
          gap: 6px;
        }

        .save_button {
          width: 54px;
          height: 28px;
          border-radius: 4px;
          border: 1px solid $text-100;
          padding: 2px 12px;
          color: $text-100;
          font-size: 12px;
          font-weight: 500;
          line-height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:disabled {
            opacity: 0.5;
          }
        }
      }

      .messages {
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: calc(100dvh - 600px);
        max-height: fit-content;
        overflow: scroll;

        .message_element {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .date_field {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            box-sizing: border-box;
            gap: 8px;

            .date_line {
              width: 50%;
              height: 0.5px;
              background-color: $divider_50;
            }

            .date {
              font-weight: 500;
              font-size: 10px;
              line-height: 14px;
              color: $text_50;
            }
          }

          .message_field {
            display: flex;
            flex-direction: column;

            .author {
              font-weight: 600;
              font-size: 12px;
              line-height: 20px;
              color: $text-100;
            }

            .message {
              font-size: 14px;
              line-height: 22px;
              color: $text_50;
              white-space: pre-line;
            }
          }
        }
      }
    }
  }
}
